<template>
  <div class="login">
    <vue-headful
      title="Log In - UNDERMRKT"
      description="Description from vue-headful"
    />
    <div class="container">
      <div class="card">
        <div class="login__title" v-show="!initialLoading">
          <font-awesome-icon :icon="['fa', 'unlock']" />
          {{ $t("login.login") }}
        </div>
        <div v-if="error" class="alert alert-danger">{{ error }}</div>
        <template v-if="initialLoading">
          <div class="initial-loader">
            <font-awesome-icon :icon="['fas', 'circle-notch']" spin />
          </div>
        </template>
        <template v-else>
          <form action="#" @submit.prevent="submit">
            <div class="form-group">
              <label for="email">Email</label>
              <div class="input-icon">
                <font-awesome-icon :icon="['fa', 'envelope']" />
                <input
                  id="email"
                  type="email"
                  class="form-control"
                  name="email"
                  value
                  required
                  autofocus
                  v-model="form.email"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="password"> {{ $t("login.password") }}</label>
              <div class="input-icon">
                <font-awesome-icon :icon="['fa', 'lock']" />
                <input
                  id="password"
                  type="password"
                  class="form-control"
                  name="password"
                  required
                  v-model="form.password"
                />
              </div>
            </div>
            <div class="form-group">
              <button-primary>
                <span v-if="!isLoading"> {{ $t("login.login") }}</span>
                <font-awesome-icon
                  v-if="isLoading == true"
                  :icon="['fas', 'circle-notch']"
                  spin
                />
              </button-primary>
              <div class="separator">
                <span> or </span>
              </div>
              <button-primary
                :onClick="SignInWithGoogle"
                :class="'g-recaptcha complete-registration google'"
                :id="'sign-in-button'"
              >
                <span class="google-bg">
                  <img src="@/assets/google_logo.svg" width="20" alt="" />
                </span>

                <span v-if="!isLoading"> Sign In with Google </span>
                <font-awesome-icon
                  v-if="isLoading == true"
                  :icon="['fas', 'circle-notch']"
                  spin
                />
              </button-primary>
            </div>
          </form>
          <div class="login__links">
            {{ $t("login.no_account") }}
            <router-link to="/register"
              >{{ $t("login.create_account") }}
            </router-link>
          </div>
          <div class="login__links">
            {{ $t("login.forgot_password") }}
            <router-link :to="{ name: 'forgot' }">{{
              $t("login.reset_password")
            }}</router-link>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/storage"; // for storage
import "firebase/firestore"; // for cloud firestore
import "firebase/functions"; // for cloud functions
import { mapMutations } from "vuex";
import UAParser from "ua-parser-js";

export const db = firebase.firestore();
export default {
  data() {
    return {
      initialLoading: true,
      geo_data: "",
      device: "",
      isLoading: false,
      form: {
        email: "",
        password: "",
      },
      error: null,
      data: [],
    };
  },
  methods: {
    ...mapMutations(["SET_LOGGED_IN"]),
    SignInWithGoogle() {
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          var credential = result.credential;
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = credential.accessToken;
          // The signed-in user info.
          var user = result.user;
          // this.user.email = user.email;
          /* eslint-disable no-console */
          var usersRef = db.collection("users").doc(user.uid);
          usersRef
            .get()
            .then((docSnapshot) => {
              //If user hash present in DB
              if (docSnapshot.exists && docSnapshot.data().user_id) {
                //Move to homepage
                this.$router.push("home");
              } else {
                this.error = "No user found";
              }
            })
            .catch((error) => {
              this.error = error.message;
            });
        })
        .catch((error) => {
          console.log(error);
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          this.error = error;
        });
    },
    async geoLocate() {
      async function httpGetAsync(url, callback) {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function () {
          if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
            callback(xmlHttp.responseText);
        };
        xmlHttp.open("GET", url, true); // true for asynchronous
        xmlHttp.send(null);
      }

      var url =
        "https://ipgeolocation.abstractapi.com/v1/?api_key=977786f851824d68ba98047686ac32a7";

      var result = {};
      await httpGetAsync(url, (response) => {
        this.geo_data = response;
      });
    },
    getDeviceInfo: function () {
      var infoSections = {};
      var parser = new UAParser();
      var userOs = parser.getOS();
      var userDevice = parser.getDevice();
      var userBrowser = parser.getBrowser();

      if (userOs && userOs.name && userOs.version) {
        infoSections["os"] = userOs.name + " " + userOs.version;
      }

      if (userBrowser && userBrowser.name && userBrowser.version) {
        infoSections["browser"] = userBrowser.name + " " + userBrowser.version;
      }

      if (userDevice && userDevice.vendor && userDevice.model) {
        infoSections["device"] = userBrowser.vendor + " " + userBrowser.model;
      } else {
        infoSections["device"] = "N/A";
      }

      if (window) {
        if (window.screen) {
          infoSections["screen_resolution"] =
            window.screen.width + "x" + window.screen.height;
          infoSections["available_screen_space"] =
            window.screen.availWidth + "x" + window.screen.availHeight;
        }

        infoSections["browser_window_size"] =
          window.innerWidth + "x" + window.innerHeight;
        infoSections["device_pixel ratio"] = window.devicePixelRatio;
      }
      this.device = infoSections;
    },
    submit() {
      this.isLoading = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(async (data) => {
          this.data = data;
          //this.$store.commit('SET_LOGGED_IN', true);
          var currentUserId = firebase.auth().currentUser.uid;
          //console.log(userID);
          var now = Math.round(+new Date() / 1000);

          var usersRef = await db.collection("users").doc(currentUserId);
          usersRef
            .update({
              last_visit: now,
              geo_data: this.geo_data,
              ua_info: this.device,
            })
            .then(() => {
              this.isLoading = false;
              this.$router.replace({ name: "home" });
            })
            .catch(() => {
              this.isLoading = false;
              this.$router.replace({ name: "home" });
            });
        })
        .catch((err) => {
          this.error = err.message;
          this.isLoading = false;
        });
    },
    checkIfUserOnLoad() {
      //Check if user is authenicated
      var user = firebase.auth().currentUser;
      if (user) {
        firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .get()
          .then((docSnapshot) => {
            console.log(docSnapshot.data());
            //If user hash present in DB
            if (docSnapshot.exists) {
              //Check if user entered email before
              this.$router.push("home");
            } else {
              firebase
                .auth()
                .signOut()
                .then(() => {
                  this.initialLoading = false;
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.initialLoading = false;
      }
    },
  },
  mounted() {
    this.geoLocate();
    this.getDeviceInfo();
    this.checkIfUserOnLoad();
  },
  beforeCreate: function () {
    document.body.className = "login";
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/_global.scss";

body {
  background: #f00;
  background-size: cover;
  background-position: center;
}

.button {
  width: 100%;
}

.login .card {
  margin: 100px auto;
  width: 360px;
  padding: 20px;
  box-shadow: 0px 0px 20px 0px rgba(#000, 0.1);
  max-width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}

.login {
  &__links {
    font-size: 14px;
    color: #424242;
    text-align: center;
    padding: 15px 0px;

    a {
      color: #000;
      font-weight: bold;
    }
  }

  &__title {
    font-size: 22px;
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e7e7e7;
    text-align: center;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 16px;
      width: 16px;
      margin-right: 5px;
      margin-bottom: 2px;
      color: #14b11a;
    }
  }
}
</style>
